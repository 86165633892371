import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <StaticQuery
      query={layoutQuery}
      render={data => {
        const logo = data.Logo.childImageSharp.fixed
        return (
          <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
            <link
              rel="stylesheet"
              href="/node_modules/dejavu-sans/css/dejavu-sans.css"
            />
            <header className="site-head">
              <div className="site-head-container">
                <a
                  className="nav-burger"
                  href={`#`}
                  onClick={() => setToggleNav(!toggleNav)}
                >
                  <div
                    className="hamburger hamburger--collapse"
                    aria-label="Menu"
                    role="button"
                    aria-controls="navigation"
                  >
                    <div className="hamburger-box">
                      <div className="hamburger-inner" />
                    </div>
                  </div>
                </a>
                <nav id="swup" class="site-head-left">
                  <ul className="nav" role="menu">
                    {/* <li className="nav-home nav-current" role="menuitem">
                    <Link to={`/`}>Home</Link>
                  </li> */}
                    <li className="nav-about" role="menuitem">
                      <Link to={`/about`}>About</Link>
                    </li>
                    <li className="nav-services" role="menuitem">
                      <Link to={`/services`}>Services</Link>
                    </li>
                  </ul>
                </nav>
                <div className="site-head-center">
                  <Link className="site-head-logo" to={`/`}>
                    <Img fixed={logo} />
                  </Link>
                </div>
                <div className="site-head-right">
                  <div className="social-links">
                    <Link to={`/contact`}>Contact</Link>
                    <a
                      href="https://github.com/e11i0t23"
                      title="GitHub"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GitHub
                    </a>
                  </div>
                </div>
              </div>
            </header>
            <main id="site-main" className="site-main">
              <div id="swup" className="transition-fade">
                {children}
              </div>
            </main>
            <footer className="site-foot">
              &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
            </footer>
          </div>
        )
      }}
    />
  )
}

const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    Logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(height: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Layout
